<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <!-- title card -->

        <Card>
          <template v-slot:body>
            <Form
              :form="form"
              :route="'satu-sehat-settings/1'"
              purpose="table"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
      
<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/satu-sehat-settings/Form.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      form: {
        client_id: "",
        client_secret: "",
        organization_id: "",
      },
      dataLoaded: false,
    };
  },

  components: {
    Card,
    Form,
  },

  methods: {
    async get() {
      this.form = await module.get("satu-sehat-settings/1");
      console.log("form", this.form);
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/satu-sehat-settings");
      } else {
        this.form["_method"] = "put";
        this.dataLoaded = true;
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Satu Sehat" },
      { title: "Kode Akses API" },
    ]);

    this.get();
  },
};
</script>